import React from 'react'
import Link from 'gatsby-link'
import SbEditable from 'storyblok-react'
import { H1 } from '@system'

const Teaser = (props) => (
  <SbEditable content={props.blok}>
    <div className="jumbotron jumbotron-fluid">
      <div className="container">
        <H1 className="display-4" component="h2">
          {props.blok.headline}
        </H1>
        <p className="lead">This is a sample page</p>
        <p className="lead">
          <Link className="btn btn-primary" to={'/'}>
            Index
          </Link>
        </p>
      </div>
    </div>
  </SbEditable>
)

export default Teaser
